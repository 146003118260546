import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col, Button } from "reactstrap"
import styled from "styled-components"
import { MdMenu, MdClose } from "react-icons/md"
import vars from "styles/variables"
import MainNav from "components/ui/MainNav"
import { useNav } from "hooks/useNav"
import SubNav from "components/ui/SubNav"
import MobileNav from "./MobileNav"
import { AnimatePresence } from "framer-motion"
import LockBodyScroll from "components/common/LockBodyScroll"
import MainLogo from "components/svg/MainLogo"

function Header({ pageId }) {
  const data = useStaticQuery(graphql`
    query MainNavQuery {
      menu: allWpMenuItem(
        filter: {
          menu: { node: { slug: { eq: "main" } } }
          parentDatabaseId: { eq: 0 }
        }
        sort: { order: ASC }
      ) {
        items: edges {
          node {
            label
            url
            slug: uri
            childItems {
              nodes {
                label
                url
                slug: uri
                childItems {
                  nodes {
                    label
                    url
                    slug: uri
                    mainNavOptions {
                      text
                      image {
                        altText
                        sourceUrl
                        mediaDetails {
                          sizes {
                            sourceUrl
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { menu } = data
  const { navState, closeSubNav } = useNav()
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  const handleHeaderLeave = () => {
    if (navState.activeSlug) closeSubNav()
  }

  const handleMobileNavClose = () => {
    setIsMobileNavOpen(false)
  }

  const headerBackgroundColour = () => {
    if (navState.navFixed && !navState.activeSlug)
      return vars.primaryTransparent(0.9)
    return vars.primary
  }

  const ref = React.useRef(null)

  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  console.log(pageId)

  return (
    <>
      <StyledHeader
        $isFixed={navState.navFixed}
        $bgColour={headerBackgroundColour()}
        onMouseLeave={handleHeaderLeave}
        className={navState.navFixed ? "is-fixed" : undefined}
      >
        <Container>
          <Row className="align-items-center">
          {pageId === 3691 ? (
            <Col
            xs="auto"
            lg="2"
            md="3"
            sm="2"
            className="text-center text-md-left py-2 py-md-0"
          >
            <Link to="/" onClick={closeSubNav}>
              <MainLogo />
            </Link>
          </Col>
          ) : (
            <Col
            md="4"
            className="text-center text-md-left py-2 py-md-0"
          >
            <Link to="/" onClick={closeSubNav}>
              <MainLogo />
            </Link>
          </Col>
          )}
            
              <Col className={`d-none ${pageId === 3691 && 'd-lg-block'}`} md="2" xl="3">
                <div
                  ref={ref}
                  class="trustpilot-widget header"
                  data-locale="en-GB"
                  data-template-id="5419b732fbfb950b10de65e5"
                  data-businessunit-id="5c80bc13f229920001fc61f4"
                  data-style-height="24px"
                  data-style-width="100%"
                  data-theme="dark"
                >
                  <a
                    href="https://uk.trustpilot.com/review/www.theheritagewindowcompany.co.uk"
                    target="_blank"
                    rel="noopener"
                  >
                    Trustpilot
                  </a>
                </div>
              </Col>
            <Col className="d-none d-md-block">
              <MainNav data={menu} />
            </Col>
            <Col className="d-md-none text-right">
              {!isMobileNavOpen && (
                <MdMenu
                  onClick={() => setIsMobileNavOpen(true)}
                  style={{ fontSize: 30 }}
                />
              )}
              {isMobileNavOpen && (
                <MdClose
                  onClick={() => setIsMobileNavOpen(false)}
                  style={{ fontSize: 30 }}
                />
              )}
            </Col>
            {pageId === 3691 && (
              <Col md="3" lg="2" className="d-none d-md-block">
                <StyledButton className="enquiry-button" href="/contact-us/">Enquire Now</StyledButton>
              </Col>
            )}
          </Row>
        </Container>
        {navState.activeSlug && <SubNav data={menu} />}
      </StyledHeader>
      {navState.activeSlug && <StyledOverlay />}
      <AnimatePresence>
        {isMobileNavOpen && (
          <MobileNav data={menu} mobileNavClose={handleMobileNavClose} />
        )}
      </AnimatePresence>
      {(isMobileNavOpen || navState.activeSlug) && <LockBodyScroll />}
    </>
  )
}

export default Header

const StyledHeader = styled.header`
  background: ${props => props.$bgColour};
  color: ${vars.white};
  margin-bottom: 1.5rem;
  position: ${props => (props.$isFixed ? "fixed" : "relative")};
  top: 0;
  width: 100%;
  z-index: 500;
`

const StyledOverlay = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${vars.blackTransparent(0.8)};
  z-index: 300;
`
const StyledButton = styled(Button)`
  border-radius: 4px;
  background: #dbb359;
  padding: 12px 16px;
  color: #fff;
`
