import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion } from "framer-motion"
import Header from "components/ui/Header"
import Footer from "components/ui/Footer"

function Layout({ children, data }) {
  
  const pageSettings = data?.page?.pageSettings || data?.post?.pageSettings
  let greyBackground = false

  if (pageSettings !== null) {
    greyBackground = pageSettings?.greyBackground
  }

  return (
    <motion.div initial="initial" animate="enter">
      <LayoutContainer>
        <Header pageId={data?.page?.databaseId} />
        {greyBackground ? (
          <main className="grey-bg">
            {children}
          </main>
        ) : (
          <main>
            {children}
          </main>
        )}
        <Footer pageId={data?.page?.databaseId} />
      </LayoutContainer>
    </motion.div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const LayoutContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`
