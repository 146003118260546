import { createGlobalStyle, css } from "styled-components"
import vars from "styles/variables"
import { optimaBase64Font } from "utils/fontUtil"
import arrow from "assets/icons/arrow_new.svg"

const styles = css`
  @font-face {
    font-family: "optima";
    src: url(${optimaBase64Font});
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.15;
    font-size: 14px;
    overflow-x: hidden;
  }

  .lock-body {
    overflow: hidden;
    @media (min-width: ${vars.screen_md_min}) {
      padding-right: 17px; //size of the scrollbar in each browser

      header.is-fixed {
        padding-right: 17px;
      }
    }
  }

  main.grey-bg {
    background: #f4f4f4;
  }

  .no-sroll {
    overflow: hidden;
  }

  main {
    flex-grow: 1;
  }

  /* If you set default link colour and hover, active, focus state then the button component
  will need to be updated also as the style changes will apply to the button */
  a {
    color: ${vars.black};
    text-decoration: none;

    &.primary-link {
      color: ${vars.primary};
      transition: ease-in-out 200ms;

      &:hover {
        color: ${vars.secondary};
      }

      &:active,
      &:focus {
        color: ${vars.primary};
      }
    }
  }

  .img-fluid {
    max-width: 100%;
  }

  h1,
  h2,
  h3 {
    font-family: "optima", sans-serif;
    font-weight: normal;
  }

  h1 {
    font-size: 2.57rem;
    @media (max-width: ${vars.screen_xs_max}) {
      font-size: 2rem;
    }
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  a,
  input,
  button,
  textarea {
    outline: none;
  }

  p {
    line-height: 1.5;
  }

  .slick-slide {
    div {
      outline: none;
    }
  }

  .trustpilot-widget.header {
    iframe {
      transform: scale(.75);
      @media (max-width:1220px) {
        height: 44px !important;
      }
    }
  }

  .slick-next:before {
    content: '';
    background: url('${arrow}');
    width:45px;
    height:45px;
    display: block;
    color:transparent;
    opacity:1;
  }


  .slick-prev:before {
    content: '';
    background: url('${arrow}');
    transform: rotate(180deg);
    width:45px;
    height:45px;
    display: block;
    color:transparent;
    opacity:1;
  }

  .slick-dots {
    li {
      &.slick-active {
        button:before {
          color: ${vars.lightBlue} !important;
        }
      }

      button:before {
        color: ${vars.white};
        text-shadow: 0 0 4px ${vars.grey_800};
        font-size: 12px;
        opacity: 1 !important;
      }
    }
  }

  .top-1rem {
    top: 1rem;
  }

  .top-1_5rem {
    top: 1.5rem;
  }

  .top-2rem {
    top: 2rem;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .gallery-slider {
  overflow:hidden;
  padding-bottom: 50px;
  .swiper {
    overflow:visible;

    .swiper-scrollbar {
    height: 8px;
    margin: 0 auto;
    max-width: 636px;
    bottom:-30px;
    left: 0;
    right:0;
    background-color: rgba(#9A9B9C, .2);
    .swiper-scrollbar-drag {
      background-color: #002D72;
    }
  }

    .swiper-slide {
    width: 308px !important;
    height: 327px;

    @media(min-width:768px) {
      width: 608px !important;
      height: 654px;
    }
    }
  }
    }

  .gallery-slider__image-container {
  overflow: hidden;
    width: 300px;
    height: 327px;

    @media(min-width:768px) {
      width: 600px;
      height: 654px;
    }
  
  &.cont {
      &--1 {
        display: flex;
        gap: 8px;
        .image {
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          width: 100%;
          height: 100%;
          transition: 0.3s ease-out;
          display: flex;
          height: calc(100% - 8px);
          margin: 8px 0px;
        }
      }

      &--2 {
        .image {
          position: relative;
          display: flex;
          flex-direction: column;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center top;
          width: 100%;
          height: calc(50% - 8px);
          transition: 0.3s ease-out;
          gap: 8px;
          margin: 8px 0px;
        }
      }

      &--3 {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        justify-content: space-between;
        align-content: flex-end;

        .image {
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          transition: 0.3s ease-out;

          &:nth-child(1) {
            height: calc(50% - 8px);
            width: calc(50% - 4px);
            align-self: flex-start;
          }

          &:nth-child(2) {
            height: calc(50% - 8px);
            width: calc(50% - 4px);
            align-self: flex-start;
          }

          &:nth-child(3) {
            position: relative;
            min-width: 100%;
            min-height: calc(50% - 8px);
          }
        }
      }

      &--4 {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        justify-content: space-between;
        align-content: flex-end;

        .image {
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          transition: 0.3s ease-out;

          &:nth-child(1) {
            height: calc(50% - 8px);
            width: calc(50% - 4px);
            align-self: flex-start;
          }

          &:nth-child(2) {
            height: calc(50% - 8px);
            width: calc(50% - 4px);
            align-self: flex-start;
          }

          &:nth-child(3) {
            height: calc(50% - 8px);
            width: calc(50% - 4px);
            align-self: flex-start;
          }

          &:nth-child(4) {
            height: calc(50% - 8px);
            width: calc(50% - 4px);
            align-self: flex-start;
          }
        }
      }

      &--5 {
        display: flex;
        flex-direction: column;
        flex-flow: row wrap;
        gap: 7px;
        align-content: flex-end;

        .image {
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          transition: 0.3s ease-out;

          &:nth-child(1) {
            @include media-breakpoint-down(md) {
              height: calc(50% - 8px);
              width: calc(70% - 0px);
            }
            height: calc(50% - 8px);
            width: calc(75% - 8px);
            align-self: flex-start;
          }

          &:nth-child(2) {
            @include media-breakpoint-down(md) {
              height: calc(50% - 8px);
              width: calc(25% - 0px);
            }
            height: calc(50% - 8px);
            width: calc(25% - 4px);
            align-self: flex-start;
          }

          &:nth-child(3) {
            @include media-breakpoint-down(md) {
              height: calc(50% - 8px);
              width: calc(31% - 0px);
            }
            height: calc(50% - 8px);
            width: calc(33% - 4px);
            //align-self: flex-end;
          }

          &:nth-child(4) {
            @include media-breakpoint-down(md) {
              height: calc(50% - 8px);
              width: calc(31% - 0px);
            }
            height: calc(50% - 8px);
            width: calc(33% - 4px);
            //align-self: flex-end;
          }

          &:nth-child(5) {
            @include media-breakpoint-down(md) {
              height: calc(50% - 8px);
              width: calc(31% - 0px);
            }
            height: calc(50% - 8px);
            width: calc(33% - 4px);
            //align-self: flex-end;
          }
        }
      }

      &--6 {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        gap: 8px;
        align-content: flex-end;

        .image {
          position: absolute;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          transition: 0.3s ease-out;

          &:nth-child(1) {
            height: calc(67% - 8px);
            left: 0;
            top: 0.5rem;
            width: calc(33% - 2px);
          }

          &:nth-child(2) {
            background-position: 50% 5% !important;
            height: calc(33% - 6px);
            right: 0;
            top: 0.5rem;
            width: calc(67% - 8px);
          }

          &:nth-child(3) {
            bottom: calc(33% + 0rem);
            height: calc(33% - 4px);
            right: calc(33% + 2px);
            width: calc(33% - 4px);
          }

          &:nth-child(4) {
            bottom: calc(33% + 0rem);
            height: calc(33% - 4px);
            right: 0;
            width: calc(33% - 8px);
          }

          &:nth-child(5) {
            bottom: 0;
            height: calc(33% - 8px);
            left: 0;
            width: calc(67% - 6px);
            background-position: 50% 15% !important;
          }

          &:nth-child(6) {
            bottom: 0;
            height: calc(33% - 8px);
            right: 0;
            width: calc(33% - 4px);
          }
        }
      }
    }
    
    .image {
      overflow: hidden;
      cursor: pointer;

    a {
      display: block;
      height: 100%;
      width: 100%;
      z-index: 3;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      transition: inherit;
      pointer-events: none;
    }
    &:hover {
      &:before {
        transform: scale(1.2);
        transition: 0.3s ease-in;
      }
    }
  }
`

export const GlobalStyle = createGlobalStyle`
  ${styles}
`
